import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledCloudServerBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledCloudServer = css`
  ${styledCloudServerBps};
  h5 {
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-bottom: 16px;
    max-width: 218px
  }
  h6 {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
  }
  p:not(:last-child) {
    margin-bottom: 24px;
  }
  span {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    align-items: flex-start;
    text-align: left;
  }
  a {
    color: #FF5800;
    :hover {
      text-decoration: underline;
    }
  }
  ul {
    font-size: 0.875rem;
    line-height: 1.71;
    color: rgba(0,0,0,0.6);
    padding-left: 20px;
    margin-bottom: 24px;
    li:not(:last-child) {
      margin-bottom: 12px;
    }
    span {
      color: rgba(0,0,0,0.87);
      font-weight: bold;
    }
  }
`

export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    letterSpacing: [0.25],
    textAlign: ['center']
  })
)

export const H5HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    letterSpacing: [0.25],
    textAlign: ['left']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['center']
  })
)

export const PBps = css(
  mq({
    width: [328, 422, 680, 690],
    textAlign: ['center']
  })
)

export const PTableBps = css(
  mq({
    width: [130, 328],
    textAlign: ['center']
  })
)

export const tableBps = css(
  mq({
    width: [328, '100%', 680, 680],
    marginLeft: [16, 0, 200, 429],
    marginRight: [16, 0, 200, 429],
    paddingTop: [50],
    paddingBottom: [34]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
`

export const table = css`
  ${tableBps};
  margin: 0 auto !important;
  border-radius: 16px;
  p {
    ${PTableBps};
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
  }
`
export const scrollTo = css`
  font-size: 14px;
  cursor: pointer;
  div:not(:last-child) {
    margin-bottom: 12px;
  }
`