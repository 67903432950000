import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Utilização Aceitável (PUA)</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Utilização Aceitável (PUA)</H5>
                <ScrollAnim.Link
                  to='disposicoes-para-todos-os-clientes'
                  offsetTop='120'
                >
                  Disposições Para<br /> Todos os Clientes
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='disposicoes-para-hospedagem-e-revenda-de-hospedagem'
                  offsetTop='120'
                >
                  Disposições para<br /> Hospedagem e<br /> Revenda de Hospedagem
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='disposicoes-para-servicos-personalizados'
                  offsetTop='120'
                >
                  Disposições para<br /> Serviços Personalizados
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='outras-utilizacoes'
                  offsetTop='120'
                >
                  Outras Utilizações
                </ScrollAnim.Link>
                <Link
                  to='/termos-de-servico'
                >
                  Voltar para Termos do Serviço
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div>
                  <Paragraph>A HostDime é dedicada a fornecer um serviço de qualidade máxima. Os termos e condições que seguem são necessários para assegurar que nós possamos continuar fornecendo o melhor serviço possível a todos os nossos clientes e ainda satisfazendo nossas responsabilidades legais e éticas ao mesmo tempo.</Paragraph>
                  <Paragraph>O não cumprimento de qualquer uma das cláusulas ou condições serão razões para a imediata desativação da conta sem qualquer notificação. A HostDime será o árbitro exclusivo no que diz respeito a violação de quaisquer destas providências.</Paragraph>
                  <Paragraph>A atividade que resultar em suspensão ou desativação de uma conta irá resultar em perda de todas as taxas já pagas. Reclamações feitas em relação a abusos de contas serão investigadas e se forem achados os culpados, será motivo para a imediata suspensão.</Paragraph>
                  <Paragraph>Reservamo-nos o direito de fazer alterações neste termo a qualquer momento. Você será informado sobre qualquer alteração necessária em seu e-mail cadastrado conosco. Se as alterações que fizermos afetarem a sua capacidade em continuar com os serviços, você poderá cancelar os serviços afetados sem penalidade. A versão mais recente deste termo poderá ser encontrado em nosso site - mais precisamente em http://www.hostdime.com/support/legal/acceptable-use-policy.php</Paragraph>
                  <Paragraph>É sua responsabilidade garantir que seus usuários finais estejam em conformidade com o presente termo. Sua incapacidade em fazê-lo caracterizará a violação do mesmo.</Paragraph>
                  <Paragraph>Para informar abusos suspeitos ou qualquer violação dessas normas, por favor entre em contato conosco através do seguinte endereço: https://www.hostdime.com.br/contatos</Paragraph>
                  <Paragraph>Este termo contém 3 seções:</Paragraph>
                  <ul>
                    <li>1. Disposições para todos os clientes</li>
                    <li>2. Disposições para Hospedagem e Revenda de Hospedagem</li>
                    <li>3. Disposições para VPS, CloudServer e Servidor Dedicado</li>
                  </ul>
                </Div>
                <Div id='disposicoes-para-todos-os-clientes'>
                  <H6>A. Disposições Para Todos os Clientes</H6>
                  <Paragraph><span>1. Utilização Ilegal</span></Paragraph>
                  <Paragraph>Os servidores da HostDime deverão ser utilizados para propósitos legais. É proibido transmissão, armazenamento ou distribuição de qualquer informação, dados ou materiais que violem qualquer lei aplicável ou regulamento. Exemplos de tais violações são informados abaixo. Esta lista destina-se como um guia para você e seus usuários finais e não representa todos os uso ilegais possíveis dos serviços.</Paragraph>
                  <ul>
                    <li>&#9679; Discurso obsceno;</li>
                    <li>&#9679; Promover a violência ou ofensa;</li>
                    <li>&#9679; Usar linguagem difamatória, ameaçadora ou abusiva;</li>
                    <li>&#9679; Jogos de azar ou atividades semelhantes, tais como programas de investimento de alto rendimento e esquemas de Ponzi ou esquemas de pirâmide;</li>
                    <li>&#9679; Sites de Warez, MP3 e conteúdo protegido por direitos autorais;</li>
                    <li>&#9679; Violações de leis de controle de exportação dos EUA;</li>
                    <li>&#9679; Violação dos direitos de propriedade intelectual de outros;</li>
                    <li>&#9679; Forjar, deturpar, omitir ou excluir cabeçalhos de mensagens, mensagens de retorno ou endereços de protocolo de internet para esconder a origem de uma mensagem;</li>
                    <li>&#9679; A criação ou envio de vírus, worms ou cavalos de Tróia, envio de SPAM, ou a prática de ataques de negação de serviço;</li>
                    <li>&#9679; Invadir e/ou ajudar outras pessoas a fim de prejudicar a segurança ou a integridade de serviços ou sistemas de HostDime;</li>
                    <li>&#9679; Agir de qualquer maneira que pode nos sujeitar a ação regulatória desfavorável, responsabilidade por qualquer razão, ou que afete negativamente a nossa imagem/reputação;</li>
                    <li>&#9679; Sistema cooperativo de "Bola de neve" é proibido, onde a pessoa é levada a acreditar que na compra de um bilhete de pequeno valor, poderá ganhar uma grande quantia, ao induzir outras pessoas a adquirir bilhetes, de forma que ao terminar a sua quota de venda de bilhetes será premiado;</li>
                    <li>&#9679; Facilitando diretamente a violação de uma lei ou regulamento e/ou instruir os outros a praticar atividades ilícitas.</li>
                  </ul>
                  <Paragraph><span>1.1. Conteúdo Difamatório ou Ilegal</span></Paragraph>
                  <Paragraph>Nós oferecemos informações gerais nessa página, e não conselho legal. Por favor consulte um advogado se você tiver um problema legal.</Paragraph>
                  <Paragraph>O que é difamação?</Paragraph>
                  <Paragraph>Em poucas palavras, difamação é a emissão de um falso julgamento sobre uma outra pessoa, o que causa a essa pessoa sofrer danos. Slander envolve julgamentos difamatórios por uma representação transitória (não fixa), usualmente uma representação oral (falada). Libel envolve julgamentos difamatórios numa média impressa ou fixa, tal como uma revista, jornal, ou site. Em termos simples, slander é a difamação falada, e libel é a difamação por meios escritos. Libel é o tipo comum de difamação com a qual nós, como um provedor de serviços, devemos lidar.</Paragraph>
                  <Paragraph>Conteúdo Online Difamatório</Paragraph>
                  <Paragraph>Uma pessoa que publica um julgamento difamatório pode ser responsável por tê-lo feito. De qualquer maneira, A "47 U.S.C. seção 230" diz que provedores de serviços online não são publicadores do conteúdo postado nos sites de seus usuários. A Seção 230 dá aos provedores de serviços de internet tanto a liberdade para permitir os clientes praticarem liberdade de expressão como também a liberdade para remover o conteúdo, em resposta às reivindicações de outros de que o conteúdo é difamatório.</Paragraph>
                  <Paragraph>A Internet é uma mídia pública, consequentemente nós acreditamos que emissões de difamações sérias devem ser resolvidas por oficiais públicos. Nós não pedimos que um cliente remova ou edite qualquer conteúdo do site até que nós recebamos uma cópia de uma ordem judicial declarando os julgamentos difamatórios. Essa imunidade não se estende a reivindicações feitas sob leis de propriedades intelectuais. Por favor, veja nossa seção de direitos de cópia para casos de infringimento de direitos de cópia.</Paragraph>
                  <Paragraph>A HostDime será o árbitro exclusivo no tocante à violação destas condições.</Paragraph>
                  <Paragraph><span>2. Envio de E-mail ou Conteúdo não autorizado - SPAM</span></Paragraph>
                  <Paragraph>A HostDime tem uma política de tolerância zero em relação a e-mail comercial não solicitado (UCE) e e-mail Lixo não-solicitado (UBE), também conhecido como "spam".</Paragraph>
                  <Paragraph>Enviar e-mails não solicitados em massa, dos ou através dos servidores da HostDime, ou usar um e-mail que está hospedado em um servidor da HostDime é estritamente proibido. A HostDime será o árbitro exclusivo para considerar uma violação deste termo. Como medida preventiva, nossos servidores compartilhados bloqueiam acesso a SMTPs externos para aplicações (scripts) que não fazem parte do sistema de e-mails do SO, evitando assim que pessoas mal-intencionadas possam utilizar nossos servidores como intermediadores de envio de SPAM. Aos que violarem haverá uma multa de R$ 200,00, sem prejuízo ao cancelamento imediato do serviço. Clientes de serviços dedicados serão responsáveis únicos pelas ações originadas a partir de seus respectivos servidores. A adição de algum dos IPs do servidor em listas de SPAM (blacklists) resultará numa multa de R$200,00 (por IP bloqueado) ao responsável. A identificação de problemas causados pelo recebimento excessivo e constante de SPAM para um determinado domínio hospedado em servidores compartilhados resultará no cancelamento do serviço.</Paragraph>
                  <Paragraph>A definição de SPAM:</Paragraph>
                  <Paragraph>Uma mensagem eletrônica é "spam" SE: (1) a identidade e contexto pessoal do receptor são irrelevantes porque a mensagem é igualmente aplicável a muitos outros potenciais receptores; e (2) o receptor não deu permissão deliberadamente, com verificação garantida, explícita e ainda: revogável para que ela fosse enviada; e (3) a transmissão e recepção da mensagem aparece para o receptor para dar um benefício desproporcional para o remetente.</Paragraph>
                  <Paragraph>Você será citado como "Abuso de UCE/UBE" se você enviar qualquer e-mail a pessoas que não tenham pedido para receber especificamente o e-mail a partir de você. A origem do envio de tal e-mail é irrelevante. Após confirmação de abuso, qualquer cliente da HostDime engajado nessa prática terá seu serviço suspendido. O spam não apenas traz tensão ao poder afetar negativamente o uptime do servidor e seu desempenho. Além disso, você também sujeita o servidor à possibilidade de entrar na lista negra.</Paragraph>
                  <Paragraph>Se seu site for enviar e-mails para assinantes, essa é uma lista de itens que você deve seguir: o número de mensagens com permissão para serem enviadas por hora, por domínio, é de 350 mensagens.</Paragraph>
                  <Paragraph>Atenção: A criação de contas isoladas possuindo como domínio principal um subdomínio, domínio inválido e/ou inexistente com o intuito de burlar a regra listada acima será motivo de desativação das contas envolvidas, sem aviso prévio. Se você necessita enviar uma grande quantidade de e-mails sugerimos a contratação de um serviço isolado para tal.</Paragraph>
                  <Paragraph>Você não deve tentar esconder, forjar ou representar de maneira errada o remetente do e-mail e o domínio do site.</Paragraph>
                  <Paragraph>E-mails em massa devem exibir especificamente como os endereços de e-mail da pessoa foram obtidos e deve indicar a frequência de envio.</Paragraph>
                  <Paragraph>E-mails em massa devem conter mecanismos de descadastramento simples e óbvios. Nós recomendamos que isso esteja em forma de um link para um sistema de descadastramento de um único clique. Por outro lado, um endereço "reply-to" válido pode ser usado como alternativa.</Paragraph>
                  <Paragraph>Todo cadastramento baseado em e-mail deve ter informações de contato válidas e não eletrônicas da organização que está enviando o e-mail no texto de cada e-mail, incluindo um número de telefone ou um endereço físico válido.</Paragraph>
                  <Paragraph>Todo e-mail em massa deve ser solicitado, quer dizer, o remetente tem uma relação existente e que se pode provar com o e-mail remetente e o receptor não requisitou não receber futuros e-mails desse remetente. A documentação da relação entre o remetente e o receptor deve ser disponível quando requisitado.</Paragraph>
                  <Paragraph>Utilizações proibidas dos Sistemas de Rede da HostDime e Serviços Relativos a SPAM</Paragraph>
                  <ul>
                    <li>&#9679; Envio de E-mail Lixo Não Solicitado ("UBE", "SPAM")</li>
                  </ul>
                  <Paragraph>O envio de qualquer forma de e-mail Lixo Não Solicitado através dos servidores da HostDime é proibido. Do mesmo modo, o envio de spam a partir de outro provedor de serviço fazendo propaganda de um site, endereço de e-mail ou a utilização de qualquer recurso hospedado nos servidores da HostDime é proibido. As contas ou serviços da HostDime não devem ser usados para solicitar clientes ou coletar 'replies to' de mensagens enviadas a partir de outro Provedor de Serviço de Internet onde essas mensagens violam essa Política ou de outro provedor.</Paragraph>
                  <ul>
                    <li>&#9679; Executar Listas de E-mail Não Confirmadas</li>
                  </ul>
                  <Paragraph>Inscrever endereços de e-mail para qualquer lista de e-mail sem a permissão expressa e verificável do dono do endereço de e-mail é proibido. Todas as listas de e-mail executadas por clientes da HostDime devem ser Closed-loop ("Confirmed Opt-in"). A mensagem confirmação de inscrição recebida de cada dono do endereço deve ser mantida em arquivo pela duração da existência da lista de e-mail. Comprar listas de endereços de e-mail de terceiros para enviar e-mails a partir de qualquer domínio hospedado na HostDime, ou referenciar qualquer conta da HostDime, é proibido.</Paragraph>
                  <ul>
                    <li>&#9679; Operar uma conta aos cuidados de, ou em conexão com, ou revendendo qualquer serviço a, pessoas ou firmas listadas no Registro Spamhaus do banco de dados de operações de Spam Conhecidas (ROKSO) em www.spamhaus.org.</li>
                  </ul>
                  <Paragraph>Nós recebemos registros automáticos da Spamhaus, AOL e SpamCop para nossas faixas de IP. Se existem mais do que três reclamações registradas contra seu site/IP em um dia, ele será suspenso/bloqueado até que uma resolução/explicação seja feita.</Paragraph>
                  <Paragraph>Nossos servidores compartilhados são configurados para a remoção automática de redirecionamento de e-mails para Yahoo!, Hotmail, AT&T, AOL, e MSNLive. Isso evita que nossos servidores sejam interpretados como fontes de origem de SPAM em casos onde haja recebimentos de e-mails não solicitados junto ao redirecionamento criado.</Paragraph>
                  <Paragraph><span>3. Segurança</span></Paragraph>
                  <Paragraph>São proibidas violações de sistema ou segurança da rede e podem resultar em responsabilidade civil e criminal. Incluem os seguintes exemplos, mas não são limitados a eles: acesso sem autorização, uso, PROBE, ou scan de sistemas de segurança ou meios de autenticação, dados ou tráfego.</Paragraph>
                  <Paragraph>Reservamo-nos o direito de suspender ou cancelar sua conta imediatamente, sem aviso prévio, como resultado de tais ações por você ou seus usuários finais.</Paragraph>
                  <Paragraph>Interferência de serviço para qualquer usuário, host ou rede inclusive, sem limitação, bombardeio de correio, flooding, ataques deliberados para sobrecarregar um sistema e ataques broadcast. Falsificação de qualquer pacote TCP-IP ou qualquer parte de uma informação de um e-mail ou de uma lista de discussão de um newsgroup.</Paragraph>
                  <Paragraph>Você está ciente de que a Internet pode ser um ambiente inseguro e é responsável por garantir a segurança dos seus dados como: nomes de usuário, senhas e informações confidenciais.</Paragraph>
                  <Paragraph>A CONTRATANTE é responsável pela implementação e gerenciamento da segurança em seus serviços, o que envolve, mas não limita-se a, todas as camadas de segurança lógica. Além disso, a CONTRATANTE é responsável pela manipulação e armazenamento dos dados, e compromete-se em efetuar por sua conta e risco exclusivos backup de dados, sistemas, programas ou conteúdo, declarando-se ciente de que nenhum backup será feito pela CONTRATADA a qualquer tempo ou a qualquer título.</Paragraph>
                  <Paragraph>A CONTRATADA não será, sob nenhuma circunstância, considerada responsável pela perda e sequestro de dados, decorrentes de, mas não limitados a, problemas de hardware, falhas de segurança lógica, erro humano, ataques cibernéticos e ruptura de negócio, incluindo, mas não limitado a, atos de qualquer órgão governamental, guerra, insurreição, incêndio, inundação, desastres naturais.</Paragraph>
                  <Paragraph><span>4. Violação de Direitos Autorais</span></Paragraph>
                  <Paragraph>A HostDime está registrada com o escritório de Copyright dos Estados Unidos de acordo com o Digital Millennium Copyright Act (DMCA). Se você perceber que seu site foi copiado por um dos nossos clientes ou um usuário final, violando de tal forma a DMCA, você deve nos fornecer a devida notificação. O DMCA estabelece diretrizes muito específicas sobre o que o seu aviso deve conter. Essas diretrizes estão disponíveis aqui: http://www.copyright.gov/title17/92chap5.html#512</Paragraph>
                  <Paragraph>O nosso agente designado para o recebimento de notificações de acordo com a DMCA é:</Paragraph>
                  <Paragraph><a href='http://hostdime.com/'>HostDime.com</a> <br /> DA: Abuse Division Manager <br /> Attn: Legal Reports <br /> 2603 Challenger Tech Court <br /> Suite 140 <br /> Orlando, FL 32826</Paragraph>
                  <Paragraph><span>5. Violação dos direitos, desde que não sejam os direitos autorais</span></Paragraph>
                  <Paragraph>Você e seus usuários finais não devem usar os nossos serviços para violar os direitos de propriedade intelectual de terceiros, incluindo, mas não limitado a: direitos autorais, marcas, segredos comerciais e direitos de patente. Você e seus usuários finais não devem usar os nossos serviços para violar os direitos reconhecidos de terceiros, incluindo, mas não limitado a: difamar, assediar, abusar ou ameaçar outros.</Paragraph>
                  <Paragraph><span>6. Pornografia Infantil e Bestialidade</span></Paragraph>
                  <Paragraph>O uso dos serviços para disseminar ou exibir imagens como a pornografia infantil, literatura erótica de criança e / ou bestialidade é extremamente proibido. Vamos encerrar imediatamente as contas de todos os clientes e/ou usuários finais que estejam usando nossos serviços para este fim sem aviso prévio. Outras informações sobre pornografia infantil pode ser encontrada em LEI Nº 11.829, DE 25 DE NOVEMBRO DE 2008.</Paragraph>
                  <Paragraph><span>7. Leis de Exportação dos EUA</span></Paragraph>
                  <Paragraph>O uso dos serviços por você e/ou seus usuários finais são regidos por leis de controle de exportação dos EUA. Essas leis se aplicam independentemente de você e/ou o usuário final residirem nos Estados Unidos ou não. Você não pode usar os nossos serviços para violar essas leis, ou para realizar negócios com terceiros provenientes de países sancionados. Mais informações sobre as leis de exportação dos EUA pode ser encontrada em: http://www.export.gov/exportcontrols.html</Paragraph>
                  <Paragraph><span>8. Endereços IP</span></Paragraph>
                  <Paragraph>Nós possuímos os endereços de IP que podem ser atribuídos a você de acordo com seu uso dos serviços. Esses endereços não podem ser cedidos ou transferidos, e são reutilizados após o término dos serviços. Reservamo-nos o direito de alterar estes endereços de IP se necessário. Somos obrigados a cumprir as políticas e procedimentos do ARIN sobre os endereços de IP. Você não pode encerrar seus serviços se uma alteração na política ARIN afetar o seu uso dos serviços.</Paragraph>
                  <Paragraph><span>9. Responsabilidade dos Clientes</span></Paragraph>
                  <Paragraph>Você é responsável por assegurar que os materiais divulgados ou publicados utilizando nossos serviços cumpram estes termos e leis relevantes, incluindo matérias que são publicadas por terceiros. Nós não revisamos, editamos, censuramos, ou assumimos a responsabilidade pelas informações criadas pelos clientes e seus usuários finais. Portanto, não podemos aceitar qualquer responsabilidade de clientes, usuários de nossos serviços através de terceiros, ou de terceiros, que divulguem/publiquem informações inadequadas, ofensivas ou ilegais.</Paragraph>
                  <Paragraph>O uso de nossos serviços requer um certo nível de conhecimento no uso das línguas na Internet, protocolos e softwares. Este nível de conhecimento varia em função da utilização prevista dos serviços. O cliente concorda que ele ou ela tem o conhecimento e as habilidades necessárias para usar adequadamente os serviços. Não é nossa responsabilidade fornecer esses conhecimentos ou habilidades para além do nosso compromisso de apoiar o uso dos nossos servidores, conforme estabelecido no TSI.</Paragraph>
                  <Paragraph><span>10. Ações sobre violações deste termo</span></Paragraph>
                  <Paragraph>A violação de qualquer dos termos deste presente termo pode resultar na suspensão imediata ou encerramento de sua conta, sem aviso prévio. Nós somos o único árbitro sobre o que constitui uma violação desta PUA.</Paragraph>
                  <Paragraph>Ao saber de uma suposta violação, vamos iniciar uma investigação. Durante este tempo talvez seja necessário restringir o acesso ao serviço. Se tal violação constituir uma ofensa criminal, poderemos notificar as autoridades competentes.</Paragraph>
                  <Paragraph>Sob nenhuma circunstância adicionaremos crédito na suspensão do serviço devido a violação de nossos termos. Você concorda em manter a HostDime livre de quaisquer reivindicações referente a suspensão ou encerramento dos serviços que de alguma forma possa prejudicar um usuário final.</Paragraph>
                  <Paragraph><span>11. Divulgação de Informações</span></Paragraph>
                  <Paragraph>Podemos divulgar informações, incluindo as informações que você e seus usuários finais podem considerar confidenciais, a fim de cumprir uma ordem judicial, intimação, citação, solicitação de descoberta, mandado, regulamento ou solicitação governamental. Nós não temos nenhuma obrigação de informar os clientes ou usuários finais que esta informação tenha sido divulgada, pois em alguns casos a divulgação pode ser proibida por lei. Também podemos divulgar essas informações quando é necessário para nos proteger ou outros contra danos.</Paragraph>
                  <Paragraph>Quanto as informações que contenham dados pessoais, nós não temos nenhuma obrigação de informar aos usuários finais que esta informação tenha sido divulgada, uma vez que cabe ao controlador desses dados a responsabilidade por tal divulgação. Aos nossos clientes, quando necessário divulgar dados pessoais, como no cumprimento da lei por exemplo, tal divulgação será registrada. A solicitação de divulgação dos dados pessoais deverá ser informada ao cliente, salvo quando tal divulgação for judicialmente obrigatória e mantido em sigilo, preservando assim a confidencialidade da investigação.</Paragraph>
                  <Paragraph><span>12. Outros Abusos</span></Paragraph>
                  <Paragraph>O uso e mau uso de nossos serviços mudam ao longo do tempo. Se determinarmos que seu uso de nossos serviços impacta negativamente na nossa capacidade de prestar serviços a outros clientes de forma satisfatória, vai além da intenção normal de uso do produto, indica falta de familiaridade com o uso dos serviços ou abuso contra nossa equipe, poderemos encerrar sua conta.</Paragraph>
                  <Paragraph><span>13. Informações de Contato</span></Paragraph>
                  <Paragraph>Ocasionalmente poderemos enviar e-mails para você sobre questões relacionadas a pagamentos, alterações nos serviços e ampliação de nossa estrutura, conforme nossa Política de Privacidade. É sua responsabilidade manter suas informações de contato atualizadas e funcionais, além de alterar tais dados em seu painel de cliente Core quando necessário. Não temos nenhuma responsabilidade sobre o fato de não conseguirmos contatá-lo a partir das informações de contato fornecidas por você.</Paragraph>
                  <Paragraph><span>14. Recusa de Serviço</span></Paragraph>
                  <Paragraph>Tratar a nossa equipe com palavras de baixo nível ou de qualquer forma inadequada será motivo para notificação de cancelamento de contrato. Nos reservamos o direito de recusa, cancelamento ou suspensão do serviço com a nossa exclusiva discrição.</Paragraph>
                </Div>
                <Div id='disposicoes-para-hospedagem-e-revenda-de-hospedagem'>
                  <H6 style={{ marginTop: 24 }}>B. Disposições para Hospedagem e Revenda de Hospedagem</H6>
                  <Paragraph>As disposições desta seção se aplicam a todos os clientes de hospedagem compartilhada (hospedagem e revenda):</Paragraph>
                  <Paragraph>Você concorda que deve utilizar o serviço de hospedagem compartilhada apenas para suas aplicações inerentes e voltadas ao intuito do serviço. Usos indevidos incluem aplicações de computação científica/grid/distribuída, dataware house, armazenamento de backups ou arquivos para download. Fica a nosso critério o que constitui ou não o bom uso dos nossos serviços de hospedagem compartilhada. Se você não tiver certeza sobre o funcionamento de um software/sistema para os serviços de hospedagem compartilhada, você deve nos consultar antes de utilizar o serviço.</Paragraph>
                  <Paragraph>Qualquer site que utilize elevados recursos do servidor, incluindo, mas não limitado a, tempo de CPU, utilização de memória e recursos de rede, deverá: (a) Optar por contratar um plano semi-dedicado ou dedicado, dependendo dos recursos necessários, ou (b) reduzir o seu consumo a um nível aceitável. A nossa Política de Abuso de Recursos define bem esses padrões aceitáveis. A HostDime será o árbitro exclusivo ao considerar o que é elevada utilização de recursos do servidor.</Paragraph>
                  <Paragraph>Você é o único responsável por prover suporte ao seu cliente final. Em planos de hospedagem compartilhada o suporte fica restrito apenas para nosso cliente direto. Permitir o acesso ftp para usuários finais em planos de hospedagem e revenda não é uma violação deste termo.</Paragraph>
                  <Paragraph>Contas em planos compartilhados (hospedagem e revenda) não podem ser utilizadas para a distribuição de Software pirateado, esquema piramidal financeiro, programas de Hackers ou arquivos, sites de Warez, MP3. Se você deseja distribuir arquivos de software e/ou multimídia, por favor entre em contato conosco no suporte@hostdime.com.br para maiores informações.</Paragraph>
                  <Paragraph>Arquivos multimídia são definidos como qualquer arquivo gráfico, áudio ou vídeo. As contas de hospedagem compartilhada da HostDime não podem ser utilizadas com o intuito de distribuir ou armazenar uma quantidade não usual de arquivos multimídia. Qualquer website que esteja armazenando arquivos multimídia em mais de 70% da utilização total, seja em termos de tamanho total ou número de arquivos, será considerado como quantidade não usual de arquivos multimídia. Em caso de incidência, os arquivos poderão ser removidos, inclusive dos backups do servidor, sem aviso prévio.</Paragraph>
                  <Paragraph>Fica terminantemente proibida a utilização dos serviços de Revenda/Hospedagem para sistemas de upload de arquivos multimídia baseados no Imageshack. Em adição, o armazenamento de arquivos multimídia para utilização remota em sites como Twitter, Facebook e outras redes de relacionamento similares e usar de forma exclusiva/dedicada para serviços de e-mail também é proibido.</Paragraph>
                  <Paragraph>Não permitimos salas de bate papo ou chat online em ambientes de hospedagem compartilhada. Também não permitimos servidores proxy, scripts, rastreadores de torrent ou clientes de torrent sendo executados a partir de nossos servidores compartilhados, além de não ser permitido instalação de quaisquer arquivos DLLs / Exe / Revenda de hospedagem, e não registrar ou instalar quaisquer terceiros DLLs/EXEs ou software em nossos servidores.</Paragraph>
                  <Paragraph>Fica terminantemente proibida a utilização de Streaming em servidores de Revenda e Hospedagem de Sites. Caso queira utilizar este tipo de serviço, o cliente deverá contratar um plano dedicado.</Paragraph>
                </Div>
                <Div id='disposicoes-para-servicos-personalizados'>
                  <H6 style={{ marginTop: 24 }}>C. Disposições para Serviços Personalizados </H6>
                  <Paragraph>As disposições desta seção aplicam-se a todos os clientes de VPS, servidores dedicados e serviços de cloud computing.</Paragraph>
                  <Paragraph>Você é o único responsável por quaisquer violações de segurança que afetarem os servidores que são administrados por você. Se o seu servidor estiver envolvido em um ataque a outro servidor ou sistema, o mesmo terá o acesso restrito e uma investigação imediata será feita para determinar a causa/origem do ataque. Nesse caso, você será responsável pelo custo de corrigir qualquer dano causado para o servidor alvo do ataque e qualquer outro requisito afetado pela violação de segurança.</Paragraph>
                  <Paragraph>Você não deve se envolver em atividades destinadas a monitorar o tráfego de Internet que não seja do seu próprio servidor. Isto significa que você não pode controlar o nosso tráfego de Internet, nem o tráfego de Internet de qualquer outra entidade.</Paragraph>
                </Div>
                <Div id='outras-utilizacoes'>
                  <H6 style={{ marginTop: 24 }}>D. Outras Utilizações</H6>
                  <Paragraph><span>1.</span> Programas em background e semelhantes</Paragraph>
                  <Paragraph>A HostDime não autoriza serviços em background em contas compartilhadas de hosting. IRC bots, Eggdrop, BitchX, XiRCON, WebProxys, Torrents, terminais/shells em PHP - ou qualquer outro programa que interfira na operação do servidor não é permitido. Aos usuários que infringirem estas regras, será aplicada suspensão na conta.</Paragraph>
                  <Paragraph><span>2.</span> IRC</Paragraph>
                  <Paragraph>A HostDime não permite servidores de IRC, IRC bots ou IRC shells operando em nossos servidores.</Paragraph>
                  <Paragraph><span>3.</span> Registro de Domínio</Paragraph>
                  <Paragraph>Tomaremos as medidas necessárias para registrar nomes de domínio em seu nome quando solicitado. No entanto, você é responsável por renovar o registro do mesmo. Nós não assumimos responsabilidades pela não renovação do domínio. Se o seu nome de domínio foi registrado conosco, você deve acompanhar e tomar as medidas apropriadas para renovar o seu domínio quando o mesmo estiver prestes a expirar. Não lidaremos com renovações a menos que você entre em contato conosco no prazo de 7 dias antes da expiração do domínio.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Utilização Aceitável (PUA)' />
      <PageTemplate
        title='Política de Utilização Aceitável (PUA)'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
